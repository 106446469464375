import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useWindowSize } from '@uidotdev/usehooks';
import { Button, Drawer, Layout, Menu } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { userAction } from '../../actions/user';
import contactsActiveImg from '../../images/icons/contacts-active.png';
import contactsImg from '../../images/icons/contacts.png';
import hambugerIcon from '../../images/icons/hambuger.png';
import historyActiveImg from '../../images/icons/history-active.png';
import historyImg from '../../images/icons/history.png';
import logoutImg from '../../images/icons/logout.png';
import settingActiveImg from '../../images/icons/setting-active.png';
import settingImg from '../../images/icons/setting.png';
import logoImg from '../../images/logo.png';
import Card from './Card';

const { Content, Sider } = Layout;

const MENUS = [
  {
    key: '/history',
    icon: (
      <>
        <img src={historyImg} width={20} alt='transactions' className='icon-normal' />
        <img src={historyActiveImg} width={20} alt='transactions' className='icon-active' />
      </>
    ),
    label: 'Lịch sử giao dịch'
  },
  {
    key: '/contacts',
    icon: (
      <>
        <img src={contactsImg} width={20} alt='contacts' className='icon-normal' />
        <img src={contactsActiveImg} width={20} alt='contacts' className='icon-active' />
      </>
    ),
    label: 'Danh bạ',
    children: [
      {
        key: '/contacts?tab=withdraw',
        label: 'Tài khoản rút cc'
      },
      {
        key: '/contacts?tab=internal',
        label: 'Tài khoản nội bộ'
      }
    ]
  },
  {
    key: '/setting',
    icon: (
      <>
        <img src={settingImg} width={20} alt='setting' className='icon-normal' />
        <img src={settingActiveImg} width={20} alt='setting' className='icon-active' />
      </>
    ),
    label: 'Cài đặt'
  }
];

const Wrapper = styled.div`
  .header {
    display: flex;
    height: 56px;
    align-items: center;
    gap: 24px;
    position: sticky;
    background: white;
    top: 0;
    z-index: 99;
    padding: 0px 16px;
    img {
      cursor: pointer;
    }
    &.sticky {
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
    }
  }
  .layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 1199px) {
      height: calc(100vh - 56px);
    }
  }
  .warning-2fa {
    background: #ffe1cc;
    height: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    color: #fd6900;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .anticon-exclamation-circle {
      font-size: 16px;
    }
  }
  .main-layout {
    padding: 60px 80px;
    overflow-y: auto;
    display: block;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #267dff;
    }
    &::-webkit-scrollbar-track {
      background-color: #e7e7e7;
      border: 1px solid #cacaca;
    }
    @media all and (max-width: 1199px) {
      overflow-y: inherit;
      padding: 32px 16px;
      height: fit-content;
    }
  }
`;

const isMenuActive = (menuName, pathname, searchParams) => {
  if (searchParams) {
    if (menuName === `/contacts${searchParams}`) return true;
  }
  if (pathname === '/' || menuName === '/') return menuName === pathname;
  return pathname.includes(menuName);
};

const Container = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { width } = useWindowSize();
  const { enabled2FA } = useSelector(({ user }) => user);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const getSelectedMenuKey = useCallback(
    (path, menu, searchParams) => {
      for (let i = 0; i < menu.length; i += 1) {
        if (menu[i].children && pathname.includes(menu[i].key)) {
          return getSelectedMenuKey(pathname, menu[i].children, searchParams);
        }
        if (!menu[i].children && isMenuActive(menu[i].key, path, searchParams)) {
          return menu[i].key;
        }
      }
      return '';
    },
    [pathname]
  );

  const getOpenKey = useCallback((path, menu) => {
    for (let i = 0; i < menu.length; i += 1) {
      if (isMenuActive(menu[i].key, path)) {
        return menu[i].key;
      }
    }
    return '';
  }, []);

  const [selectedMenuKey, setSelectedMenuKey] = useState(getSelectedMenuKey(pathname, MENUS, search));
  const [openKeys, setOpenKeys] = useState([getOpenKey(pathname, MENUS)]);

  useEffect(() => {
    setSelectedMenuKey(getSelectedMenuKey(pathname, MENUS, search));
    setOpenKeys([getOpenKey(pathname, MENUS)]);
  }, [pathname, getSelectedMenuKey, getOpenKey, search]);

  useEffect(() => {
    window.onscroll = function () {
      const header = document.getElementById('header');
      if (header) {
        const sticky = 56;
        if (window.scrollY > sticky) {
          header.classList.add('sticky');
        } else {
          header.classList.remove('sticky');
        }
      }
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  return (
    <Wrapper>
      {width < 1200 && (
        <div className='header' id='header'>
          <img src={hambugerIcon} width={24} onClick={() => setOpen(true)} />
          <img src={logoImg} height={32} onClick={() => history.push('/history')} />
          <Drawer placement='left' closable={false} onClose={() => setOpen(false)} open={open} width={278}>
            <Sider width={278} style={{ background: 'white', display: 'flex', height: '100vh' }}>
              <div className='logo'>
                <img src={logoImg} />
              </div>
              <Card setOpen={setOpen} />
              <Menu
                mode='inline'
                items={MENUS}
                selectedKeys={[selectedMenuKey]}
                openKeys={openKeys}
                onOpenChange={(keys) => {
                  setOpenKeys(keys);
                }}
                onClick={async ({ key }) => {
                  setOpen(false);
                  setSelectedMenuKey(key);
                  history.push(key);
                }}
              />
              <div className='logout' onClick={() => userAction.signOut()}>
                <div className='logout-item'>
                  <img src={logoutImg} /> Đăng xuất
                </div>
              </div>
            </Sider>
          </Drawer>
        </div>
      )}
      <div className='layout'>
        {!enabled2FA && (
          <div className='warning-2fa'>
            <ExclamationCircleOutlined /> Cài đặt bảo mật 2 lớp{' '}
            <Button type='primary' className='btn-warning' onClick={() => history.push('/enable-2fa')}>
              Cài đặt ngay
            </Button>
          </div>
        )}
        <Layout>
          <Sider width={278} style={{ background: 'white' }}>
            <div className='logo'>
              <img src={logoImg} />
            </div>
            <Card />
            <Menu
              mode='inline'
              items={MENUS}
              selectedKeys={[selectedMenuKey]}
              openKeys={openKeys}
              onOpenChange={(keys) => {
                setOpenKeys(keys);
              }}
              onClick={async ({ key }) => {
                setSelectedMenuKey(key);
                history.push(key);
              }}
            />
            <div className='logout' onClick={() => userAction.signOut()}>
              <div className='logout-item'>
                <img src={logoutImg} /> Đăng xuất
              </div>
            </div>
          </Sider>
          <Layout className='main-layout'>
            <Content>
              <div className='children'>{children}</div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </Wrapper>
  );
};

export default Container;
