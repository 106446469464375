import jwt_decode from 'jwt-decode';
import cookie from 'react-cookies';

import { store } from '../reducers';
import { ActionType } from '../reducers/user';
import { apis } from '../services';
import { COOKIE_NAME } from '../utils/constants';

const login = async (values) => {
  const { user } = store.getState();
  if (user && user.isLoggedIn) {
    return user;
  }
  try {
    const result = await apis.login(values);
    if (result?.accessToken) {
      const profile = jwt_decode(result?.accessToken);
      if (profile?.role === 'USER') {
        if (profile?.enabled2FA) {
          signIn({
            ...profile,
            accessToken: result?.accessToken,
            isLoggedIn: profile?.verify2fa
          });
          cookie.save(COOKIE_NAME, {
            ...profile,
            accessToken: result?.accessToken,
            isLoggedIn: profile?.verify2fa
          });
          await fetchProfile();
          return profile?.verify2fa ? result : { needVerify2fa: true };
        } else {
          signIn({
            ...profile,
            accessToken: result?.accessToken,
            isLoggedIn: true
          });
          cookie.save(COOKIE_NAME, {
            ...profile,
            accessToken: result?.accessToken,
            isLoggedIn: true
          });
          await fetchProfile();
          return result;
        }
      }
      return null;
    }
    if (result?.statusCode) {
      return result;
    }
  } catch (error) {
    console.log(error);
    await signOut();
    return undefined;
  }
};

const loginGoogle = async (result) => {
  try {
    if (result?.accessToken) {
      const profile = jwt_decode(result?.accessToken);
      if (profile?.role === 'USER') {
        if (profile?.enabled2FA) {
          signIn({
            ...profile,
            accessToken: result?.accessToken,
            isLoggedIn: profile?.verify2fa
          });
          cookie.save(COOKIE_NAME, {
            ...profile,
            accessToken: result?.accessToken,
            isLoggedIn: profile?.verify2fa
          });
          await fetchProfile();
          return profile?.verify2fa ? result : { needVerify2fa: true };
        } else {
          signIn({
            ...profile,
            accessToken: result?.accessToken,
            isLoggedIn: true
          });
          cookie.save(COOKIE_NAME, {
            ...profile,
            accessToken: result?.accessToken,
            isLoggedIn: true
          });
          await fetchProfile();
          return result;
        }
      }
      return null;
    }
    return null;
  } catch (error) {
    console.log(error);
    await signOut();
    return undefined;
  }
};

const enable2faSuccess = () => {
  store.dispatch({
    type: ActionType.USER_ENABLE_2FA_SUCCESS
  });
};

const input2fa = async (values) => {
  try {
    const result = await apis.input2fa(values);
    if (result?.accessToken) {
      const profile = jwt_decode(result?.accessToken);
      if (profile?.role === 'USER') {
        signIn({
          ...profile,
          accessToken: result?.accessToken,
          isLoggedIn: true
        });
        cookie.save(COOKIE_NAME, {
          ...profile,
          accessToken: result?.accessToken,
          isLoggedIn: true
        });
        await fetchProfile();
        return result;
      }
      return null;
    }
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const fetchProfile = async () => {
  const { user } = store.getState();
  if (user.isLoggedIn) {
    const result = await apis.fetchProfile();
    if (result?.id) {
      updateUserInfo(result);
    }
  }
};

const fetchBalance = async () => {
  try {
    const { user } = store.getState();
    if (user.isLoggedIn) {
      const result = await apis.fetchBalance();
      if (result) {
        updateUserInfo(Number(result));
      }
    }
  } catch (error) {}
};

const fetchSystemConfigs = async () => {
  try {
    const result = await apis.fetchSystemConfigs();
    if (result) {
      updateSystemConfigs({
        depositFee: Number(result?.depositFee || 0),
        withdrawFee: Number(result?.withdrawFee || 0)
      });
    }
  } catch (error) {}
};

const updateUserInfo = (data) => {
  store.dispatch({
    type: ActionType.USER_UPDATE,
    data
  });
};

const updateSystemConfigs = (data) => {
  store.dispatch({
    type: ActionType.SYSTEM_CONFIGS,
    data
  });
};

const signIn = (data) => {
  store.dispatch({
    type: ActionType.USER_LOGIN,
    data
  });
};

const signOut = () => {
  cookie.remove(COOKIE_NAME);
  store.dispatch({
    type: ActionType.USER_LOGOUT
  });
};

export const userAction = {
  login,
  signOut,
  signIn,
  input2fa,
  enable2faSuccess,
  fetchBalance,
  fetchProfile,
  fetchSystemConfigs,
  loginGoogle
};
