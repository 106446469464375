import { client } from './axios';

const login = (body) => client.post(`/auth/login`, body);
const fetchMyAccount = () => client.get(`/virtual-accounts/info`);
const fetchProfile = () => client.get(`/users/profile`);
const fetchBalance = () => client.get(`/users/balance`);
const fetch2FA = () => client.get(`/auth/2fa-secret`);
const enable2fa = (body) => client.post(`/auth/enable-2fa`, body);
const input2fa = (body) => client.post(`/auth/2fa`, body);
const fetchTransactions = (params) => client.get(`/users/transactions`, { params });
const fetchBanks = () => client.get(`/bank-config/all`);
const withdraw = (body) => client.post(`/transactions/withdraw`, body);
const transfer = (body) => client.post(`/transactions/internal-transfer`, body);
const fetchFavoriteBank = (params) => client.get(`/favorite-bank`, { params });
const addFavoriteBank = (body) => client.post(`/favorite-bank`, body);
const deleteFavoriteBank = (id) => client.delete(`/favorite-bank/${id}`);
const fetchFavoriteTransfer = (params) => client.get(`/favorite-users`, { params });
const addFavoriteTransfer = (body) => client.post(`/favorite-users`, body);
const deleteFavoriteTransfer = (id) => client.delete(`/favorite-users/${id}`);
const findUser = (id) => client.get(`/users/${id}`);
const fetchSystemConfigs = (id) => client.get(`/system-configs`);
const register = (body) => client.post(`/auth/register`, body);
const verify = (body) => client.post(`/auth/verify`, body);
const resendVerify = (body) => client.post(`/auth/resend-verify`, body);
const checkemail = (params) => client.get(`/auth/checkemail`, { params });
const googleRegister = (body) => client.post(`/auth/google/register`, body);
const googleLogin = (body) => client.post(`/auth/google/login`, body);
const forgot = (body) => client.post(`/auth/forgot-password-email`, body);
const verifyToken = (body) => client.post(`/auth/verify-token`, body, { ignoreError: true });
const resetPasswordEmail = (body) => client.post(`/auth/reset-password-email`, body);
const changePass = (body) => client.post(`/auth/change-password`, body);
const exportData = () => client.post(`/transactions/export`, {}, { ignoreError: true, isCSV: true });

const apis = {
  exportData,
  changePass,
  verifyToken,
  resetPasswordEmail,
  forgot,
  login,
  register,
  verify,
  resendVerify,
  checkemail,
  googleRegister,
  googleLogin,
  fetchMyAccount,
  fetch2FA,
  enable2fa,
  input2fa,
  fetchProfile,
  fetchBalance,
  fetchTransactions,
  fetchBanks,
  withdraw,
  fetchFavoriteBank,
  addFavoriteBank,
  fetchFavoriteTransfer,
  addFavoriteTransfer,
  findUser,
  transfer,
  deleteFavoriteBank,
  deleteFavoriteTransfer,
  fetchSystemConfigs
};

export default apis;
