/* eslint-disable react-hooks/exhaustive-deps */
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useCallback, useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import './App.scss';
import PrivateRoute from './PrivateRoute';
import PrivateRoute2FA from './PrivateRoute2FA';
import { userAction } from './actions/user';
import LoadingIndicator from './components/LoadingIndicator';
import ScrollToTop from './components/ScrollToTop';
import Contacts from './pages/Contacts/Loadable';
import Forgot from './pages/Forgot/Loadable';
import History from './pages/History/Loadable';
import Input2FA from './pages/Input2FA/Loadable';
import Landing from './pages/Landing/Loadable';
import Login from './pages/Login/Loadable';
import Maintenance from './pages/Maintenance/Loadable';
import Register from './pages/Register/Loadable';
import Setting2FA from './pages/Setting2FA/Loadable';
import Setting from './pages/Setting/Loadable';
import Term from './pages/Term/Loadable';
import Transfer from './pages/Transfer/Loadable';
import VerifyForgot from './pages/VerifyForgot/Loadable';
import Withdraw from './pages/Withdraw/Loadable';
import { COOKIE_NAME, INTERVAL_TIME_BALANCE } from './utils/constants';

let interval;

function App() {
  const { isMaintenance } = useSelector(({ user }) => user);
  const [isReadyUser, setIsReadyUser] = useState(false);

  const loadUser = useCallback(async () => {
    try {
      const profile = cookie.load(COOKIE_NAME);
      if (profile) {
        await userAction.signIn(profile);
        await userAction.fetchProfile();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsReadyUser(true);
    }
  }, []);

  const fetchBalance = useCallback(async () => {
    clearInterval(interval);
    interval = setInterval(async () => {
      try {
        await userAction.fetchBalance();
      } catch (error) {}
    }, INTERVAL_TIME_BALANCE);
  }, []);

  const fetchSystemConfigs = useCallback(async () => {
    try {
      await userAction.fetchSystemConfigs();
    } catch (error) {}
  }, []);

  useEffect(() => {
    Aos.init();
    loadUser();
  }, []);

  useEffect(() => {
    fetchBalance();
    fetchSystemConfigs();
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (isMaintenance) {
    return <Maintenance />;
  }

  if (!isReadyUser)
    return (
      <div className='App'>
        <LoadingIndicator />
      </div>
    );

  return (
    <div className='App'>
      <ScrollToTop />
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/forgot' component={Forgot} />
        <Route path='/verify-forgot' component={VerifyForgot} />
        <Route path='/register' component={Register} />
        <Route path='/input-2fa' component={Input2FA} />
        <Route path='/term' component={Term} />
        <Route path='/maintenance' component={Maintenance} />
        <PrivateRoute path='/setting' component={Setting} />
        <PrivateRoute2FA path='/withdraw' component={Withdraw} />
        <PrivateRoute2FA path='/transfer' component={Transfer} />
        <PrivateRoute path='/enable-2fa' component={Setting2FA} />
        <PrivateRoute path='/contacts' component={Contacts} />
        <PrivateRoute path='/history' component={History} />
        <Route path='/' component={Landing} />
      </Switch>
    </div>
  );
}

export default App;
