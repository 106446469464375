/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import Container from './components/Container';

const PrivateRoute2FA = ({ component: Component, ...rest }) => {
  const { isLoggedIn, enabled2FA } = useSelector(({ user }) => user);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          enabled2FA ? (
            <Container>
              <Component {...props} />
            </Container>
          ) : (
            <Redirect to='/enable-2fa' />
          )
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );
};

export default PrivateRoute2FA;
