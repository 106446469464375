export const ActionType = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_UPDATE: 'USER_UPDATE',
  SYSTEM_CONFIGS: 'SYSTEM_CONFIGS',
  USER_ENABLE_2FA_SUCCESS: 'USER_ENABLE_2FA_SUCCESS',
  MAINTENANCE: 'MAINTENANCE'
};

const initState = {
  accessToken: null,
  isLoggedIn: false,
  profile: null,
  isMaintenance: false
};

const user = (state = initState, { type, data }) => {
  switch (type) {
    case ActionType.USER_LOGIN:
      return { ...state, ...data };
    case ActionType.USER_UPDATE:
      return { ...state, ...data };
    case ActionType.SYSTEM_CONFIGS:
      return { ...state, ...data };
    case ActionType.USER_ENABLE_2FA_SUCCESS:
      return { ...state, enabled2FA: true };
    case ActionType.MAINTENANCE:
      return { ...state, isMaintenance: true };
    case ActionType.USER_LOGOUT:
      return {
        ...state,
        ...initState
      };
    default:
      return state;
  }
};

export default user;
