import { Modal } from 'antd';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import copyIcon from '../../images/icons/copy.png';
import { apis } from '../../services';
import { copyToClipboard } from './../../utils';
import Loading from './../Loading';
import LogoBank from './../LogoBank';

const ModalDeposit = ({ isOpen, setIsOpen }) => {
  const onCancel = () => {
    setIsOpen(false);
  };

  const { data, isLoading } = useQuery('fetchMyAccount', apis.fetchMyAccount);
  const { data: dataBanks, isLoading: isLoadingBanks } = useQuery('fetchBank', () =>
    fetch('https://api.vietqr.io/v2/banks').then((res) => res.json())
  );

  const bankInfo = useMemo(() => {
    if (dataBanks?.data) {
      return dataBanks?.data.find((item) => item?.shortName?.toLowerCase() === data?.bankName?.toLowerCase());
    }
    return null;
  }, [data?.bankName, dataBanks?.data]);

  return (
    <Modal
      title='Tài khoản của tôi'
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      width={406}
      wrapClassName='modal-center modal-deposit'
    >
      {data?.number && !isLoading && !isLoadingBanks ? (
        <div className='modal-deposit-box'>
          <div className='qr-code'>
            <img src={data?.qrCode} alt='qr-code' />
          </div>
          <div className='account'>
            <div className='logo-bank'>
              <LogoBank name={data?.code} />
            </div>
            <div className='account-info'>
              <div className='bank-name'>{bankInfo?.shortName}</div>
              <div className='account-name'>{data?.name}</div>
              <div className='account-number'>
                {data?.number} <img src={copyIcon} alt='copy' onClick={() => copyToClipboard(data?.number)} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};

export default ModalDeposit;
