import { notification } from 'antd';
import axios from 'axios';
import { stringify } from 'query-string';

import { store } from '../reducers';
import { ActionType } from '../reducers/user';
import { API_URL } from '../utils/constants';

const DEFAULT_ERROR = 'Something went wrong. Please try again!';

const ERRORS = [405, 408];

const onError = ({ response }) => {
  let errorCodeText = DEFAULT_ERROR;
  if (response) {
    const { data, errors } = response;
    const errorCode = errors?.statusCode || data?.statusCode;
    errorCodeText = errors?.message || data?.message || DEFAULT_ERROR;
    if (errorCode === 503) {
      store.dispatch({
        type: ActionType.MAINTENANCE
      });
    } else if (errorCode === 401) {
      notification.error({
        key: 'axios',
        message: `${errorCode} - ${errorCodeText}`
      });
      store.dispatch({
        type: ActionType.USER_LOGOUT
      });
    } else if (errorCode < 500) {
      notification.error({
        key: 'axios',
        message: errorCodeText
      });
    } else {
      notification.error({
        key: 'axios',
        message: `${errorCode} - ${errorCodeText}`
      });
    }
  } else {
    notification.error({
      key: 'axios',
      message: 'Cannot connect to Server'
    });
  }
  throw new Error(errorCodeText);
};

const beforeRequest = (config) => {
  const { accessToken } = store.getState().user;
  if (accessToken) {
    Object.assign(config.headers, { Authorization: `Bearer ${accessToken}` });
  }
  if (config.data instanceof FormData) {
    Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });
  }
  return config;
};

const client = axios.create({
  baseURL: API_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: '' })
});

client.interceptors.request.use(beforeRequest);

// eslint-disable-next-line no-shadow
[client].forEach((client) => {
  client.interceptors.response.use(({ data: response, config }) => {
    const { data, success } = response;
    if (config.isCSV) return response;
    if (success) return data;
    if (ERRORS.indexOf(response?.errors?.statusCode) > -1 || config.ignoreError) {
      return response?.errors;
    }
    onError({ response });
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({});
  }, onError);
});

export { client };
