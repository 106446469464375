import bidvImg from '../../images/bank-icons/BIDV.png';
import mbbankImg from '../../images/bank-icons/MBBANK.png';
import msbImg from '../../images/bank-icons/MSB.png';
import tcbImg from '../../images/bank-icons/TECHCOMBANK.png';
import vpbImg from '../../images/bank-icons/VPBANK.png';

const LogoBank = ({ name }) => {
  switch (name) {
    case 'TECHCOMBANK':
      return <img src={tcbImg} alt='logo' />;
    case 'BIDV':
      return <img src={bidvImg} alt='logo' />;
    case 'MSB':
      return <img src={msbImg} alt='logo' />;
    case 'VPBANK':
      return <img src={vpbImg} alt='logo' />;
    default:
      return <img src={mbbankImg} alt='logo' />;
  }
};

export default LogoBank;
