import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CardImg from '../../images/card.png';
import outActiveImg from '../../images/icons/out-active.png';
import outImg from '../../images/icons/out.png';
import plusActiveImg from '../../images/icons/plus-active.png';
import plusImg from '../../images/icons/plus.png';
import sendActiveImg from '../../images/icons/send-active.png';
import sendImg from '../../images/icons/send.png';
import { formatMoney } from '../../utils';
import { COOKIE_BALANCE_NAME } from '../../utils/constants';
import ModalDeposit from './ModalDeposit';

const Wrapper = styled.div`
  border-bottom: 1px solid #ebedf1;
  padding-bottom: 8px;
  margin-bottom: 12px;
  .card {
    position: relative;
    height: 165px;
    z-index: 1;
    img {
      position: absolute;
      max-width: 258px;
      z-index: 0;
      left: 10px;
    }
    .card-info {
      position: relative;
      z-index: 1;
      color: #fff;
      padding: 20px 40px 30px;
      .info {
        margin-bottom: 52px;
        .name {
          text-shadow: 0px 1px 2px #6274ac;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 6px;
        }
        .sub {
          text-shadow: 0px 1px 2px #6274ac;
          font-size: 13px;
          font-weight: 400;
        }
      }
      .group-balance {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .balance {
          text-shadow: 0px 1px 2px #6274ac;
          font-size: 18px;
          font-weight: 600;
        }
        .anticon {
          font-size: 20px;
        }
      }
    }
  }
  .actions {
    padding: 0px 20px 8px;
    gap: 8px;
    display: flex;
    .action {
      border-radius: 8px;
      background: #f6f7f9;
      width: 100%;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      img.active {
        display: none;
      }
      &:hover {
        background: #1c1c1c;
        color: #fff;
        img.normal {
          display: none;
        }
        img.active {
          display: block;
        }
      }
    }
  }
`;

const Card = ({ setOpen }) => {
  const history = useHistory();
  const [isOpenDeposit, setIsOpenDeposit] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const { balance, id, fullname } = useSelector(({ user }) => user);

  const toggleShow = (value) => {
    cookie.save(COOKIE_BALANCE_NAME, value);
    setIsShow(value);
  };

  useEffect(() => {
    const show = cookie.load(COOKIE_BALANCE_NAME);
    if (show === 'false') {
      setIsShow(false);
    } else {
      setIsShow(true);
    }
  }, []);

  return (
    <Wrapper>
      <div className='card'>
        <img src={CardImg} alt='card' />
        <div className='card-info'>
          <div className='info'>
            <div className='name'>{fullname}</div>
            <div className='sub'>UID: {id}</div>
          </div>
          <div className='group-balance'>
            <div className='balance'>{isShow ? `${formatMoney(balance)} cc` : '******'}</div>
            {isShow ? (
              <EyeOutlined onClick={() => toggleShow(false)} />
            ) : (
              <EyeInvisibleOutlined onClick={() => toggleShow(true)} />
            )}
          </div>
        </div>
      </div>
      <div className='actions'>
        <div
          className='action'
          onClick={() => {
            setOpen?.(false);
            setIsOpenDeposit(true);
          }}
        >
          <img src={plusImg} alt='plus' className='normal' />
          <img src={plusActiveImg} alt='plus' className='active' />
          Nạp tiền
        </div>
        <div
          className='action'
          onClick={() => {
            setOpen?.(false);
            history.push('/withdraw');
          }}
        >
          <img src={outImg} alt='out' className='normal' />
          <img src={outActiveImg} alt='out' className='active' />
          Rút
        </div>
      </div>
      <div
        className='actions'
        onClick={() => {
          setOpen?.(false);
          history.push('/transfer');
        }}
      >
        <div className='action'>
          <img src={sendImg} alt='send' className='normal' />
          <img src={sendActiveImg} alt='send' className='active' />
          Chuyển cc
        </div>
      </div>
      {isOpenDeposit && <ModalDeposit isOpen={isOpenDeposit} setIsOpen={setIsOpenDeposit} />}
    </Wrapper>
  );
};

export default Card;
