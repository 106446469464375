export const isMobile = () => {
  const mobiles = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
  return mobiles.some((matches) => navigator.userAgent.match(matches));
};

export const COOKIE_NAME = 'ac-fe';
export const COOKIE_BALANCE_NAME = 'ac-fe-balance';

export const API_URL = process.env.REACT_APP_API;

export const BANKS = [
  {
    code: 'Techcombank - TCB',
    name: 'Ngân hàng TMCP Kỹ thương Việt Nam',
    shortName: 'TECHCOMBANK'
  },
  {
    code: 'MB BANK - MBB',
    name: 'Ngân hàng TMCP Quân Đội',
    shortName: 'MBBANK'
  },
  {
    code: 'MSB',
    name: 'Ngân hàng TMCP Hàng Hải Việt Nam',
    shortName: 'MSB'
  },
  {
    code: 'BIDV',
    name: 'Ngân hàng TMCP Đầu tư và Phát triển Việt Nam',
    shortName: 'BIDV'
  },
  {
    code: 'VP Bank',
    name: 'Ngân hàng TMCP Việt Nam Thịnh Vượng',
    shortName: 'VPBANK'
  }
];

export const INTERVAL_TIME_BALANCE = 10000; // 10s
