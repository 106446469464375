export const ActionType = {
  SYSTEM_CONFIG: 'SYSTEM_CONFIG'
};

const system = (
  state = {
    chainId: null,
    chainName: null,
    lastProcessedBlock: null,
    mintingFee: 0,
    withdrawFee: {},
    erc20Contract: null,
    chanel: null,
    marketFee: 0
  },
  { type, data }
) => {
  switch (type) {
    case ActionType.SYSTEM_CONFIG:
      return { ...state, ...data };
    default:
      return state;
  }
};

export default system;
